<script lang="jsx">
export default {
  name: 'ProductItemRankingList',
  functional: true,
  props: {
    /**
     * small 列表
     * big 商详
     */
    size: {
      type: String,
      default: 'small'
    },
    link: {
      type: String,
      default: ''
    },
    rankingList: {
      type: Object, 
      default: () => ({})
    },
    iconLink: {
      type: String,
      default: ''
    },
    // 区分新老样式 A:老样式 B:新样式
    styleType: {
      type: String,
      default: 'A'
    },
    blockClick: {
      type: Boolean,
    },
    isRankNewStyle: {
      type: Boolean,
      default: false
    },
    language: {
      type: Object, 
      default: () => ({})
    }
  },
  render(h, { props, data, listeners }) {
    const styles = {}

    const iconSrc = props.isRankNewStyle ? 'https://img.ltwebstatic.com/images3_ccc/2024/09/11/44/1726040176dbfd0d416b9fca80fe1a267dd1f42fa1.png' : (props.rankingList?.icon || props.iconLink)
    const handleClickRankingList = () => {
      listeners?.['clickLink']?.()
      if (props.link && !props.blockClick) location.href = props.link
    }

    let rankingEl = null
    if (props.styleType === 'A') { 
      const color = props.rankingList?.fontColor || '#A86104'
      const backgroundColor = props.rankingList?.backgroundColor || '#FFF8EB'

      if (color) {
        styles.color = color
      }

      if (backgroundColor) {
        styles.backgroundColor = backgroundColor
      }

      rankingEl = (
        <div
          class={[
            'product-item__ranking-list',
            props.size === 'big' && 'product-item__ranking-list_big',
            data.class,
            data.staticClass,
          ]}
          style={[styles, data.style, data.staticStyle]}
          onClick={handleClickRankingList}
        >
          {
            iconSrc ? (
              <img 
                src={iconSrc} 
                class={{
                  'ranking-list__img': true,
                  'ranking-list__img_big': props.size === 'big'
                }}
              />
            ) : null
          }
          <div class="ranking-list__text">
            <p class="ranking-list__desc">
              #{ props.rankingList.score } { props.rankingList.rankTypeText } { props.rankingList.composeIdText }
            </p>
            { props.link ? <i class="suiiconfont sui_icon_more_right_12px"></i> : null }
          </div>
        </div>
      )
    } else if (props.styleType === 'B'){
      rankingEl = (
        <div
          class={[
            'product-item__ranking-item',
            props.isRankNewStyle && 'new-style',
            data.class,
            data.staticClass,
          ]}
          style={[styles, data.style, data.staticStyle]}
          onClick={handleClickRankingList}
        >
          <p class="ranking-item__primary-wrapper">
            {
              iconSrc ? (
                <img 
                  src={iconSrc} 
                  class="ranking-item__img"
                />
              ) : null
            }
            <span class="ranking-item__text">
              {props.isRankNewStyle ? (props.language.SHEIN_KEY_PWA_34207 || `No.`) : `#`}{ props.rankingList.score } { props.rankingList.rankTypeText }
            </span>
          </p>
          <span class="ranking-item__second">
            { props.rankingList.composeIdText }
          </span>
          { props.link ? <i class="suiiconfont sui_icon_more_right_12px_2"></i> : null }
        </div>
      )
    }
    return props.rankingList ? rankingEl : null
  },
}
</script>

<style lang="less">
.product-item {
  &__ranking-list {
    .font-dpr(20px);
    position: relative;
    margin-top: 0.1067rem;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    height: 0.4267rem;
    padding: 0 0.1067rem 0 0.5867rem;
    vertical-align: bottom;
    &_big {
      .font-dpr(22px);
      height: .5333rem;
      margin-top: 0;
      margin-bottom: .2133rem;
    }
    .ranking-list {
      &__img {
        position: absolute;
        top: 0;
        left: 0.1333rem;
        width: 0.32rem;
        height: 0.3733rem;
        &_big {
          .font-dpr(22px);
          width: .3733rem;
          height: .4267rem;
        }
      }
      &__text {
        display: flex;
        flex-wrap: nowrap;
        .text-overflow();
      }
      &__desc {
        max-width: 100%;
        .text-overflow();
      }
    }
  }
  &__ranking-item {
    &.new-style{
      background: #FFF7E2;
      padding: 0.186rem 0.213rem;
      color: #D58700;
      width: 100%;
      
      .ranking-item__primary-wrapper{
        color: #D58700;
        font-weight: 700;
        .font-dpr(24px);
        background: none;
        padding: 0 0.106rem 0 0; 
      }
      .ranking-item__second{
        color: #D58700;
        .font-dpr(20px);
        flex: 1
      }
      .ranking-item__img {
        width: .426rem;
        height: .426rem;
        margin-right: .08rem;
      }
      .ranking-item__text{
        font-family: 'SF Pro';
      }
    }
    .font-dpr(22px);
    line-height: .3467rem;
    max-width: 100%;
    display: inline-flex;
    align-items: center;
    color: #B4810F;
    margin-bottom: .2133rem;
    padding: .08rem;
    border-radius: .0533rem;
    background: linear-gradient(90deg, rgba(251, 240, 221, 1) 0%, rgba(251, 240, 221, 0) 100%);
    .ranking-item {
      &__primary-wrapper {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        height: .4533rem;
        max-width: calc(100% - .32rem);
        padding: .0533rem .08rem .0533rem .1067rem;
        color:#FFFFFF;
        font-weight: 400;
        border-radius: .0533rem;
        background: linear-gradient(90deg, rgba(222, 174, 63, 1) 0%, rgba(229, 202, 102, 1) 100%);
      }
      &__text {
        max-width: 100%;
        .text-overflow();
      }
      &__second {
        .text-overflow();
        max-width: 100%;
        margin-left: .1067rem;
      }
      &__img {
          width: .2667rem;
          height: .2667rem;
          margin-right: .08rem;
        }
    }
  }
}
</style>
